import type { ComponentType } from "react"
import { useState, useCallback } from "react"

const contentToCopy = "ux@alexdull.com"

export function withCopy(Component): ComponentType {
    return (props) => {
        const [isCopied, setIsCopied] = useState(false)

        const handleClick = () => {
            navigator.clipboard?.writeText(contentToCopy)

            setIsCopied(true)

            setTimeout(() => {
                setIsCopied(false)
            }, 4000)
        }

        return (
            <Component
                {...props}
                variant={isCopied ? "Copied" : "Copy"}
                onClick={handleClick}
            />
        )
    }
}
